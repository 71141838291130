import { Pages }           from "./Pages";
import ReactDOM            from "react-dom";
import React          from "react";
import {ContractView} from "../components/ContractView";



export class ContractsDetail extends Pages {

    initPageHandlers() {
        super.initPageHandlers();
        this.data = window.diaryJsData;
        ReactDOM.render(
            <ContractView
                element={this.data.element}/>,
            document.getElementById('contractsDetail')
        );

    }
}
